import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const Agreement = () => import("views/Agreement.vue");
const AboutUs = () => import("views/AboutUs.vue");


const UserAgreement =  () => import('views/UserAgreement.vue')
const Login = () => import("views/Login.vue");

const Register = () => import("views/Register.vue");

const Home = () => import("views/Home.vue");
const ProductDetail = () => import("views/ProductDetail.vue");
// a+b详情页
const ComboDetail = () => import("views/comboDetail.vue");

// 一元购详情页
const  ONEBUYDetail = ()=>import('views/ONEBUYDetail.vue')

// 套餐详情页
const SetMealDetail = () => import("views/setMealDetail.vue");
// 预售活动+限时购 详情页
const ActivityModuleDetail = () => import("views/ActivityModuleDetail.vue");

// 首页积分商城详情页
const IntegralMallDetail = () => import("views/IntegralMallDetail.vue");
const Cart = () => import("views/Cart.vue");

// 订单结算详情页
const GoodsAmount = () => import("views/GoodsAmount.vue");

// 积分兑换订单结算详情页
const IntegralMallAmount = () => import("views/IntegralMallAmount.vue");
const OrderSumbit = () => import("views/OrderSumbit.vue");

const PaySuccess = () => import("components/order/PaySuccess.vue");

const My = () => import("views/My.vue");

// 最近购买
const MyOftenBuy = ()=>import('views/my/MyOftenBuy')
const MyOrder = () => import("views/my/MyOrder");
const FirstInfo = () => import("views/my/FirstInfo.vue");
const Report = () => import("views/my/Report.vue");
const Natural = () => import("views/my/Natural.vue");
const MyInvoice = () => import("views/my/MyInvoice.vue");
const MyConsigneeInfo = () => import("views/my/MyConsigneeInfo.vue");

// 关联门店
const MyAssociatedAccount = () => import("views/my/MyAssociatedAccount.vue");
const Repass = () => import("views/my/Repass.vue");

const WTSDOWN = () => import("views/my/wtsDown.vue");

// 设置支付密码
const Paypass = () => import("views/my/Paypass.vue");
//重置支付密码
const Repaypass = () => import("views/my/Repaypass.vue");
// 更换绑定手机号
const ChangeMobile = () => import("views/my/ChangeMobile.vue");
const Coupon = () => import("views/my/Coupon.vue");
const Credit = () => import("views/my/Credit.vue");
const TransferAccount = () => import("views/my/TransferAccount.vue");
const Ypt = () => import("views/my/Ypt.vue");
//我的余额
const Balance = () => import("views/my/Balance.vue");
// 我的积分
const Points =() => import("views/my/Points.vue");

//我的赠品
const MemberGiftList  =() => import("views/my/MemberGiftList.vue");

// 充值
const Recharge = () => import("views/my/Recharge.vue");

//我的账户
const MyAccount = () => import("views/my/MyAccount.vue");
//订单发票信息
const MyOrderInvoice = () => import("views/my/MyOrderInvoice.vue");
//申请售后
const ApplySales = () => import("views/my/ApplySales.vue");
//订单详情
const OrderDetail = () => import("views/my/OrderDetail.vue");
// 订单评价
const EvaluateOrder = () => import("views/my/EvaluateOrder.vue");

//售后、退款
const AfterRefund = () => import("views/my/AfterRefund.vue");
//售后详情
const AfterRefundDetail = () => import("views/my/AfterRefundDetail.vue");
//授信回款
const CreditRecharge = () => import("views/my/CreditRecharge.vue");

//产品分类
const ProductCategory = () => import("views/ProductCategory.vue");

//导航产品分类
const ProductType = () => import("views/ProductType.vue");

 // 快速下单
 const QuickOrder=()=> import("views/QuickOrder.vue");

// a+b活动列表页面
const ComboType = () => import("views/comboType.vue");
//套餐列表页面
const SetMealList = () => import("views/SetMealList.vue");

// 预售+限时抢购列表页面
const ActivityModuleList = () => import("views/ActivityModuleList.vue");


//  首页根据标签获取列表页
const TagIdList = ()=>import('views/TagIdList.vue')

// 首页营销活动商品列表页
const MarketingList = () => import("views/MarketingList.vue");

// 首页积分商城列表页
const IntegralMallList = () => import("views/IntegralMallList.vue");


// 活动专题页
const TopicPage = () => import("views/TopicPage.vue");

//今日资讯
const CurrentMessage = () => import("views/CurrentMessage.vue");

// 文章详情
const CurrentMessageDetail = () => import("views/CurrentMessageDetail.vue");

//关于我们
const About = () => import("views/About.vue");

// 领取优惠券
const GetCoupon = () => import("views/GetCoupon.vue");

// 智能采购
const SmartSourcing = () => import("views/SmartSourcing.vue");
const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      title: "首页",
    },
  },
  {
    path: "/agreement",
    name: "Agreement",
    component: Agreement,
    meta: {
      title: "服务协议",
    },
  },
  {
    path: "/aboutUs",
    name: "AboutUs",
    component: AboutUs,
    meta: {
      title: "关于/联系我们",
    },
  },

  
  {
    path: '/useragreement',
    name: 'UserAgreement',
    component:UserAgreement,
    meta:{
      title:"用户协议"
    },
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      title: "关于我们",
    },
  },
  {
    path: "/getCoupon",
    name: "GetCoupon",
    component: GetCoupon,
    meta: {
      title: "领取优惠券",
    },
  },
  {
    path: "/SmartSourcing",
    name: "SmartSourcing",
    component: SmartSourcing,
    meta: {
      title: "智能采购",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "登录",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      title: "注册",
    },
  },
  // 文章列表
  {
    path: "/currentMessage",
    name: "CurrentMessage",
    component: CurrentMessage,
    meta: {
      title: "文章列表",
    },
  },
  // 文章详情
  {
    path: "/currentMessageDetail",
    name: "CurrentMessageDetail",
    component: CurrentMessageDetail,
    meta: {
      title: "文章详情",
    },
  },
  {
    path: "/productcategory",
    name: "ProductCategory",
    component: ProductCategory,
    meta: {
      title: "商品分类",
    },
  },
  // 导航产品分类
  {
    path: "/producttype",
    name: "ProductType",
    component: ProductType,
    meta: {
      title: "商品分类",
    },
  },

  // 快速下单
  {
    path: "/quickOrder",
    name: "QuickOrder",
    component: QuickOrder,
    meta: {
      title: "快速下单",
    },
  },
  // 套餐活动+a+b列表
  {
    path: "/comboType",
    name: "ComboType",
    component: ComboType,
    meta: {
      title: "A+B活动列表",
    },
  },

  // 套餐列表页面
  {
    path: "/setMealList",
    name: "SetMealList",
    component: SetMealList,
    meta: {
      title: "套餐列表",
    },
  },
  // ActivityModuleList
  {
    path: "/activityModuleList",
    name: "ActivityModuleList",
    component: ActivityModuleList,
    meta: {
      title: "活动列表",
    },
  },

// TagIdList
{
  path: '/tagIdList',
  name: 'TagIdList',
  component:TagIdList,
  meta:{
    title:"标签列表"
  },
},


  // IntegralMallList
  {
    path: "/integralMallList",
    name: "IntegralMallList",
    component: IntegralMallList,
    meta: {
      title: "积分商城",
    },
  },



   //活动专题页 TopicPage
   {
    path: '/topicPage',
    name: 'TopicPage',
    component: TopicPage,
    meta: {
      title: "专题页",
    },
  },

  // MarketingList
  // 活动营销商品列表
  {
    path: "/marketinglist",
    name: "MarketingList",
    component: MarketingList,
    meta: {
      title: "营销活动",
    },
  },
  {
    path: "/my",
    component: My,
    meta: {
      title: "个人中心",
    },
    children: [
      {
        path: "myorder",
        name: "MyOrder",
        component: MyOrder,
        meta: {
          title: "我的订单",
        },
      },
      {
        path: "afterRefund",
        name: "AfterRefund",
        component: AfterRefund,
        meta: {
          title: "售后/退款",
        },
      },
      {
        path: 'myOftenBuy',
        name: 'MyOftenBuy',
        component: MyOftenBuy,
        meta:{
          title:"最近购买"
        },
      },
      {
        path: "firstInfo",
        name: "FirstInfo",
        component: FirstInfo,
        meta: {
          title: "首营资料",
        },
      },
      {
        path: "balance",
        name: "Balance",
        component: Balance,
        meta: {
          title: "我的余额",
        },
      },
      {
        path: "points",
        name: "Points",
        component: Points,
        meta: {
          title: "我的积分",
        },
      },

      {
        path: "memberGiftList",
        name: "memberGiftList",
        component: MemberGiftList,
        meta: {
          title: "我的赠品",
        },
      },

      {
        path: "transferAccount",
        name: "TransferAccount",
        component: TransferAccount,
        meta: {
          title: "转账",
        },
      },
      {
        path: "recharge",
        name: "Recharge",
        component: Recharge,
        meta: {
          title: "充值",
        },
      },
      {
        path: "ypt",
        name: "Ypt",
        component: Ypt,
        meta: {
          title: "代付宝",
        },
      },
      {
        path: "report",
        name: "Report",
        component: Report,
        meta: {
          title: "订单药检报告",
        },
      },
      {
        path: "natural",
        name: "Natural",
        component: Natural,
        meta: {
          title: "我的资质",
        },
      },
      {
        path: "myInvoice",
        name: "MyInvoice",
        component: MyInvoice,
        meta: {
          title: "账号信息",
        },
      },

      {
        path: "myAssociatedAccount",
        name: "MyAssociatedAccount",
        component: MyAssociatedAccount,
        meta: {
          title: "关联门店",
        },
      },
      {
        path: "myConsigneeInfo",
        name: "MyConsigneeInfo",
        component: MyConsigneeInfo,
        meta: {
          title: "收货地址",
        },
      },
      {
        path: "wtsDown",
        name: "WTSDOWN",
        component: WTSDOWN,
        meta: {
          title: "委托书下载",
        },
      },
      {
        path: "repass",
        name: "Repass",
        component: Repass,
        meta: {
          title: "修改密码",
        },
      },
      {
        path: "paypass",
        name: "Paypass",
        component: Paypass,
        meta: {
          title: "设置支付密码",
        },
      },
      {
        path: "repaypass",
        name: "Repaypass",
        component: Repaypass,
        meta: {
          title: "修改支付密码",
        },
      },
      {
        path: "changemobile",
        name: "ChangeMobile",
        component: ChangeMobile,
        meta: {
          title: "更换绑定手机号",
        },
      },

      {
        path: "coupon",
        name: "Coupon",
        component: Coupon,
        meta: {
          title: "优惠券",
        },
      },

     
      {
        path: "creditRecharge",
        name: "CreditRecharge",
        component: CreditRecharge,
        meta: {
          title: "授信回款",
        },
      },
      {
        path: "myAccount",
        name: "MyAccount",
        component: MyAccount,
        meta: {
          title: "我的账户",
        },
      },
      {
        path: "/",
        redirect: "MyAccount",
      },
    ],
  },
  {
    path: "/credit",
    name: "Credit",
    component: Credit,
    meta: {
      title: "我的授信",
    },
  },
 
  {
    path: "/afterRefundDetail",
    name: "AfterRefundDetail",
    component: AfterRefundDetail,
    meta: {
      title: "售后详情",
    },
  },
  {
    path: "/orderDetail",
    name: "OrderDetail",
    component: OrderDetail,
    meta: {
      title: "订单详情",
    },
  },
  {
    path: "/evaluateOrder",
    name: "EvaluateOrder",
    component: EvaluateOrder,
    meta: {
      title: "订单评价",
    },
  },
  {
    path: "/applySales",
    name: "ApplySales",
    component: ApplySales,
    meta: {
      title: "申请售后",
    },
  },
  {
    path: "/myorderinvoice",
    name: "MyOrderInvoice",
    component: MyOrderInvoice,
    meta: {
      title: "发票信息",
    },
  },
  {
    path: "/amount",
    name: "GoodsAmount",
    component: GoodsAmount,
    meta: {
      title: "订单结算",
    },
  },

  // IntegralMallAmount
  {
    path: "/integralMallAmount",
    name: "IntegralMallAmount",
    component: IntegralMallAmount,
    meta: {
      title: "积分订单结算",
    },
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
    meta: {
      title: "购物车",
    },
  },
  {
    path: "/ordersumbit", //提交订单页面
    name: "OrderSumbit",
    component: OrderSumbit,
    meta: {
      title: "订单支付",
    },
  },
  {
    path: "/productdetail",
    name: "ProductDetail",
    component: ProductDetail,
    meta: {
      title: "商品详情",
    },
  },

  {
    path: "/comboDetail",
    name: "ComboDetail",
    component: ComboDetail,
    meta: {
      title: "商品详情",
    },
  },

  {
    path: '/oneBuyDetail',
    name: 'ONEBUYDetail',
    component:ONEBUYDetail,
    meta:{
      title:"商品详情"
    }
  },

  {
    path: "/setMealDetail",
    name: "SetMealDetail",
    component: SetMealDetail,
    meta: {
      title: "套餐商品详情",
    },
  },

  {
    path: "/integralMallDetail",
    name: "IntegralMallDetail",
    component: IntegralMallDetail,
    meta: {
      title: "积分商品详情",
    },
  },
  {
    path: "/activityModuleDetail",
    name: "ActivityModuleDetail",
    component: ActivityModuleDetail,
    meta: {
      title: "活动商品详情",
    },
  },
  {
    path: "/freeMail", //小额包邮页面
    name: "FreeMail",
    component: () => import("views/FreeMail.vue"),
    meta: {
      title: "小额包邮"
    }
  },
  {
    path: "/freeMailDetail", //小额包邮商品详情
    name: "FreeMailDetail",
    component: () => import("views/freeMailDetail.vue"),
    meta: {
      title: "小额包邮详情"
    }
  },

  {
    path: "/flashGroupList", //限时拼团列表页面
    name: "FlashGroupList",
    component: () => import("views/FlashGroupList.vue"),
    meta: {
      title: "限时拼团列表"
    }
  },
  {
    path: "/flashGroupDetail", //限时拼团商品详情
    name: "FlashGroupDetail",
    component: () => import("views/FlashGroupDetail.vue"),
    meta: {
      title: "限时拼团详情"
    }
  },


  {
    path: "/paysuccess", //订单支付成功页面
    name: "PaySuccess",
    component: PaySuccess,
    meta: {
      title: "支付成功",
    },
  },
  {
    path: "/",
    redirect: "/login",
    meta: {
      title: "登录",
    },
  },
];

const router = new VueRouter({
  linkActiveClass: "active",
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
//重复点击路由报错解决方法
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
