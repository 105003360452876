const TokenKey = 'token';
//获取token
export const getToken=()=>{
    return sessionStorage.getItem(TokenKey)
}
//设置token
export const setToken=(token)=>{
    return sessionStorage.setItem(TokenKey, token)
}
//删除token
export const removeToken=()=>{
    return sessionStorage.removeItem(TokenKey)
}


export const setLocalToken=(token)=>{
    return localStorage.setItem(TokenKey, token)
}
export const getLocalToken=()=>{
    return localStorage.getItem(TokenKey)
}
export const removeLocalToken=()=>{
    return localStorage.removeItem(TokenKey)
}


