// 商品接口
import axios  from 'utils/axios'
import Qs from 'qs'

// 根据商品id获取佣金商品设置  /blade-commission/commissionproduct/getByGoodsId
export const getByGoodsId = (params) => {
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-commission/commissionproduct/getByGoodsId'; 
        axios.get(url,{params:{productId:params}})
        .then((data)=>{
            var result =data;
            return  resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
  }

// 获取分类下的商品分页  /blade-commission/commissionproductcategory/getProductByCategory     /commissionproduct/productPage
export const getProductByCategory = (params) => {
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-commission/commissionproductcategory/getProductByCategory'; 
        axios.get(url,
            // {params:{categroyId:params}}
            {params}
        )
        .then((data)=>{
            var result =data;
            return  resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
  }

// 今日资讯  文章详情页
export const getAritcledDetail = (params) => {
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-content/article/detail'; 
        axios.get(url,{params:{id:params}})
        .then((data)=>{
            var result =data;
            return  resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
  }
  // 获取文章列表接口   /blade-content/article/list    get      type	0 系统公告 1 资讯   current	当前页   size	每页的数量
export const articleList=(params)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-content/article/list'; 
        axios.get(url,{
            params:params
        })
        .then((data)=>{
            resolve(data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}
  // 首页分类   post请求
export const ProductcategoryTree=()=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-business/productcategory/tree';
        axios.post(url)
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}
// 点击分类  获取商品数据    get
export const GetProducts = (params) => {
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-business/productcategory/getProductsPageByCategoryId'; 
        // axios.get(url,{params:params})
        axios.get(url,{
            params:params
        })
        .then((data)=>{
            var result =data;
            return  resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
  }


  // PC-前端 通过标签id获取丸药标签下的商品  +s 带分页  /api/blade-business/producttag/getTagIndexPagesById   
export const getTagIndexPagesById=(datas)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-business/producttag/getTagIndexPagesById'; 
        axios.get(url,{
            //请求头配置  
            headers:{  'Content-Type':'application/json;charset=UTF-8'},
             //参数列表
             params:{tagId:datas.tagId,current:datas.current,size:datas.size},
       })
        .then((data)=>{
            resolve(data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}
// 新品上市商品 /blade-business/product/newArrivalPage   get
// export const GetNewArrivalPage = (params) => {
//     return new Promise((resolve,reject)=>{
//         let url = '/api/blade-business/product/newArrivalPage'; 
//         // axios.get(url,{params:params})
//         axios.get(url,{
//             params:params
//         })
//         .then((data)=>{
//             var result =data;
//             return  resolve(result)
//         })
//         .catch((err)=>{
//             reject(err)
//         })
//     })
//   }

// export const GetNewArrivalPage =  () => {
//     return new Promise((resolve,reject)=>{
//         let url = '/api/blade-business/producttag/getByParameter';     
//         let data = {
//             key:"tag_name",
//             value:"新品上市"
//         };
//         axios({
//             url:url,
//             method:"post",
//             data:Qs.stringify(data)
//         })
//         .then((data)=>{
//             var result =data;
//             return  resolve(result)
//         })
//         .catch((err)=>{
//             reject(err)
//         })
//     })
//   }

export const GetIdNewArrivalPage = (params) => {
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-business/producttag/getProductsByTagId'; 
        axios.get(url,{
            params:params
        })
        .then((data)=>{
            var result =data;
            return  resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
  }



// 商品导航栏 搜索接口  /blade-business/productcategory/page    get
export const SearchProducts = (params) => {
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-business/product/page'; 
        axios.get(url,{
            params:params
        })
        .then((data)=>{
            var result =data;
            return  resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
  }

// 商品搜索框输入字段，实时搜索   /blade-search/search/product
export const SearchProduct = (params) => {
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-search/search/product';
        axios.get(url,{
            params:params
        })
        .then((data)=>{
            var result =data;
            return  resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
  }

//   搜索热词推荐  api/blade-content/hotwords/list
export const HotWords = (params) => {
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-content/hotwords/list';
        axios.get(url,{
            params:params
        })
        .then((data)=>{
            var result =data;
            return  resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
  }

//   商品搜索框搜索接口  /blade-business/search/product    keyword   pageable
export const SearchValProducts = (params) => {
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-business/product/search';
        // let data = {
        //     phone:phone,
        //     type:num
        // };
        axios({
            url:url,
            method:"post",
            data:Qs.stringify(params)
        })
        .then((data)=>{
            var result =data;
            return  resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
  }

  //   商品搜索框搜索接口,没有消息再次搜索  blade-search/search/recommend/product    keyword   pageable
export const SearchValProductsAgain = (params) => {
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-search/search/recommend/product';
        // let data = {
        //     phone:phone,
        //     type:num
        // };
        axios({
            url:url,
            method:"post",
            data:Qs.stringify(params)
        })
        .then((data)=>{
            var result =data;
            return  resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
  }




//   商品详情页 get
export const GetProductDetail = (params) => {
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-business/product/detail'; 
        // axios.get(url,{params:params})
        axios.get(url,{
            params:params
        })
        .then((data)=>{
            var result =data;
            return  resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
  }


  
// 限时拼团商品详情  /blade-promotion/flashGroup/detail
export const GetFlashGroupDetail = (params) => {
    return new Promise((resolve, reject) => {
      let url = '/api/blade-promotion/flashGroup/detail';
      axios.get(url, {
          //请求头配置  
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          //参数列表
          params: params,
        })
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }


//   通过商品id获取关联商品信息
// http://localhost/blade-business/product/getRelevanceProducts?id=
export const GetRelevanceProducts = (params) => {
    return new Promise((resolve,reject)=>{
        let url='/api/blade-business/product/getRelevanceProducts';
        // axios.get(url,{params:params})
        axios.get(url,{
            params:params
        })
        .then((data)=>{
            var result =data;
            return  resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
  }






  // 购物车   详情接口    http://172.16.0.76/    /blade-order/ordercart/detail

export const CartDetail=(cartId)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-order/ordercart/detail'; 
        axios.get(url,{
            params:{
                id:cartId
            }
        })
        .then((data)=>{
            resolve(data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}


//加入购物车   post请求
// 传入实例[{"isRecentExpiration":0,"quantity":10,"productId":1},{"isRecentExpiration":1,"quantity":10,"productId":1}]
export const AddCart=(data)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-order/ordercart/save';
        axios({
            headers:{
                'Content-Type':'application/json',
            },
            url:url,
            method:"post",
            data:JSON.stringify(data)
        })
        .then((res)=>{
            var result = res;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}
 
// 加入一元购购物车  /saveOneBuy
// 传入实例[{"isRecentExpiration":0,"quantity":10,"productId":1,cartType 4},{"isRecentExpiration":1,"quantity":10,"productId":1,cartType 4}]
export const SaveOneBuy=(data)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-order/ordercart/saveOneBuy';
        axios({
            headers:{
                'Content-Type':'application/json',
            },
            url:url,
            method:"post",
            data:JSON.stringify(data)
        })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

//一元购 购物车数量   /oneBuyCount
export const OneBuyCount=()=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-order/ordercart/oneBuyCount';
        axios.get(url,{
            //请求头配置  
            headers:{  'Content-Type':'application/x-www-form-urlencoded'},
       })
        .then((data)=>{
            resolve(data.data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 积分商城商品兑换  生成订单 /blade-order/pointsorder/submit
export const CreateOrder=(data)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-order/pointsorder/submit';
        axios({
            headers:{
                'Content-Type':'application/json',
            },
            url:url,
            method:"post",
            data:JSON.stringify(data)
        })
        .then((res)=>{
            var result = res;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

//购物车数量
export const CartCount=()=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-order/ordercart/count-pc';
        axios.get(url,{
            //请求头配置  
            headers:{  'Content-Type':'application/x-www-form-urlencoded'},
       })
        .then((data)=>{
            resolve(data.data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}
//购物车列表页   get请求
export const CartList=(data)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-order/ordercart/list';
        axios.get(url,{
            //请求头配置  
            headers:{  'Content-Type':'application/x-www-form-urlencoded'},
           //参数列表
           params:data,
          
       })
        .then((res)=>{
            resolve(res.data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

//购物车商品删除接口   post   传ids
export const removeCart=(ids)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-order/ordercart/remove';
        axios({
            headers:{
                'Content-Type':'application/x-www-form-urlencoded',
            },
            url:url,
            method:"post",
            data:Qs.stringify(ids)
        })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 购物车修改商品数量接口 post    
//传入实例[{"quantity":10,"productId":1},{"quantity":10,"productId":1}]
// "quantity":10,"productId":1
export const updateCart=(data)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-order/ordercart/update';
        axios({
            headers:{
                'Content-Type':'application/json',
            },
            url:url,
            method:"post",
            data:JSON.stringify(data)
        })
        .then((res)=>{
            var result = res;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}
// import {getToken } from "utils/session.js";
//商品对应首营资料 单个商品首营资料下载，传入对应商品id即可   /blade-business/product/downloadDrugCertificateOss    get    id
export const DownloadFirstTest=(goodsId)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-business/product/downloadDrugCertificateOss?id='+goodsId;
        axios.get(url)
        // axios.get(url,{
        //     params:{
        //         headers: {
        //             'Content-Type': 'application/json',
        //             // 'Authorization': 'Bearer ' + getToken()
        //         },
        //         // xsrfHeaderName: 'Authorization',
        //         // responseType: 'blob',  //设置响应的数据类型为一个包含二进制数据的 Blob 对象，必须设置
        //     }
        // })
        .then((data)=>{
            resolve(data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 根据订单id批量商品首营资料下载    /blade-business/product/downloadDrugCertificateList    get   orderId
export const DownloadFirstTestList=(goodsId)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-business/product/downloadDrugCertificateList'; 
        axios.get(url,{
            params:{
                headers:{  'Content-Type':'application/json;charset=utf-8'},
                id:goodsId
            }
        })
        .then((data)=>{
            resolve(data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 商品  单条件分页  /blade-business/product/getProductByParameterForPage    get
// current 当前页     size  每页的数量
export const GetProductPage=(params)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-business/product/getProductExplainPage';
         axios.get(url,{
            headers:{  'Content-Type':'application/x-www-form-urlencoded'},
             params:params
         })
        .then((data)=>{
            resolve(data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}


// 生成预订单接口   http://172.16.0.76/  /blade-order/order/generateOrder     商品页商品直接购买，不通过购物车



// 委托书下载   /blade-system/tenant/fileInfo   get



export const DownloadWTS=()=>{
    return new Promise((resolve,reject)=>{
        let url = '/api//blade-system/tenant/fileInfo';
        axios.get(url,{
            headers:{  'Content-Type':'application/x-www-form-urlencoded'},
         })
        .then((data)=>{
            resolve(data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

